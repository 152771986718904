import styled from '@emotion/styled';
import { colors } from 'styles/colors';
import media from 'styles/media';
import tabBackground from 'assets/images/tab_background.jpeg';
import { css } from '@emotion/react';

export const BannerHorizontalBar = styled.div`
  height: 10px;
  background: ${colors.BLUE1};

  ${media.desktop} {
    height: 30px;
    min-width: 1355px;
  }
`;

export const CategoryBody = styled.div`
  padding: 0 36px;
  display: flex;
  flex-direction: column;
  padding-bottom: 220px;

  ${media.desktop} {
    padding: 0;
    min-width: 1355px;
    max-width: 1370px;
    margin: 0 auto;
    padding-bottom: 215px;
  }
`;

export const CategoryListContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 0 4px;

  ${media.desktop} {
    padding: 0;
    margin-bottom: 130px;
  }
`;

export const CategoryHorizontalBar = styled.div`
  height: 0.5px;
  background: ${colors.GRAY2};
  flex: 1;

  ${media.desktop} {
    height: 1px;
  }
`;

export const CategoryVerticalBar = styled.div`
  width: 0.5px;
  height: 14px;
  background: ${colors.GRAY2};

  ${media.desktop} {
    width: 1px;
    height: 35px;
  }
`;

export const CategoryList = styled.ul`
  display: flex;
  align-items: center;
`;

export const CategoryListItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.48px;
  color: ${colors.GRAY1};

  width: 79px;
  height: 18px;

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 700;
      color: ${colors.BLUE1};
    `}

  ${media.desktop} {
    width: 241px;
    height: 45px;

    font-size: 30px;
    line-height: 45px;
    letter-spacing: -1.2px;
  }
`;

export const CategoryBackground = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 220px;

  background-image: ${`url(${tabBackground})`};
  background-size: cover;
  background-position: center;

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(
      179deg,
      #ffffff 0%,
      #fffffff9 0%,
      #ffffffe6 24%,
      #ffffff00 100%
    );
  }

  ${media.desktop} {
    height: 650px;
    min-width: 1355px;

    div {
      height: 650px;
    }
  }
`;
