import {
  HeaderBlock,
  HeaderContainer,
  HeaderLink,
  LogoWrapper,
} from './Header.styles';
import logo from 'assets/images/kga_logo_1.png';

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderBlock>
        <LogoWrapper>
          <img src={logo} alt="logo" />
        </LogoWrapper>

        <HeaderLink
          href="https://www.kgagolf.or.kr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          대한골프협회 공식 사이트 바로가기
        </HeaderLink>
      </HeaderBlock>
    </HeaderContainer>
  );
};

export default Header;
