import WHS_image1 from "../assets/images/whs_image1.png";

export const videoDataWHS = [
  {
    ebKey: "kga_whs_video_26",
    title: "핸디캡을 갖고 라운드 합시다.",
    subTitle: "KOREA GOLF STANDARD",
    description: "",
    issueDate: "재생시간 : 4분 34초",
    img: "whs_image26",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_26_export",
  },
  {
    ebKey: "whs_image27", //키값 잘못 들어 갔음
    title: "핸디캡 OX 퀴즈",
    subTitle: "KOREA GOLF STANDARD",
    description: "",
    issueDate: "재생시간 : 4분 22초",
    img: "whs_image27",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_27_export",
  },
  {
    ebKey: "video_1",
    title: "WHS 공식 소개 동영상",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "게리 플레이어, 아니카 소렌스탐 등",
    issueDate: "재생시간 : 1분 07초",
    img: "whs_image1",
    url: "http://kgabook.or.kr/eBook/KGA_WHS_movie_1",
  },
  {
    ebKey: "kga_whs_video_2",
    title: "WHS 기본 내용 안내 1",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 1. [WHS안내]",
    issueDate: "재생시간 : 1분 06초",
    img: "whs_image2",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_2_export",
  },
  {
    ebKey: "kga_whs_video_3",
    title: "WHS 기본 내용 안내 2",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 1. [WHS안내]",
    issueDate: "재생시간 : 1분 23초",
    img: "whs_image3",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_3_export",
  },
  {
    ebKey: "kga_whs_video_4",
    title: "코스레이팅시스템",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 1. [WHS안내]",
    issueDate: "재생시간 : 1분 26초",
    img: "whs_image4",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_4_export",
  },
  {
    ebKey: "kga_whs_video_5",
    title: "PCC",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 1. [WHS안내]",
    issueDate: "재생시간 : 1분 23초",
    img: "whs_image5",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_5_export",
  },
  {
    ebKey: "kga_whs_video_6",
    title: "수용할 수 있는 스코어",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 1. [WHS안내]",
    issueDate: "재생시간 : 1분 26초",
    img: "whs_image6",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_6_export",
  },
  {
    ebKey: "kga_whs_video_7",
    title: "네트더블보기",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 1. [WHS안내]",
    issueDate: "재생시간 : 1분 17초",
    img: "whs_image7",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_7_export",
  },
  {
    ebKey: "kga_whs_video_8",
    title: "매일 업데이트",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 1. [WHS안내]",
    issueDate: "재생시간 : 1분 17초",
    img: "whs_image8",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_8_export",
  },
  {
    ebKey: "kga_whs_video_9",
    title: "플레잉핸디캡",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 1. [WHS안내]",
    issueDate: "재생시간 : 1분 08초",
    img: "whs_image9",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_9_export",
  },
];

export const videoDataGAEGWAL = [
  {
    ebKey: "kga_whs_video_10",
    title: "수용할 수 있는 스코어",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 2. [개괄]",
    issueDate: "재생시간 : 1분 11초",
    img: "whs_image10",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_10_export",
  },
  {
    ebKey: "kga_whs_video_11",
    title: "네트더블보기",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 2. [개괄]",
    issueDate: "재생시간 : 1분 08초",
    img: "whs_image11",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_11_export",
  },
  {
    ebKey: "kga_whs_video_12",
    title: "스코어의 제때 제출",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 2. [개괄]",
    issueDate: "재생시간 : 1분 13초",
    img: "whs_image12",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_12_export",
  },
  {
    ebKey: "kga_whs_video_13",
    title: "핸디캡인덱스의 즉시 업데이트",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 2. [개괄]",
    issueDate: "재생시간 : 1분 03초",
    img: "whs_image13",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_13_export",
  },
  {
    ebKey: "kga_whs_video_14",
    title: "최대 핸디캡인덱스",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 2. [개괄]",
    issueDate: "재생시간 : 1분 10초",
    img: "whs_image14",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_14_export",
  },
  {
    ebKey: "kga_whs_video_15",
    title: "플레잉컨디션계산",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 2. [개괄]",
    issueDate: "재생시간 : 1분 10초",
    img: "whs_image15",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_15_export",
  },
  {
    ebKey: "kga_whs_video_16",
    title: "예외적인 스코어",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 2. [개괄]",
    issueDate: "재생시간 : 1분 09초",
    img: "whs_image16",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_16_export",
  },
  {
    ebKey: "kga_whs_video_17",
    title: "캡",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 2. [개괄]",
    issueDate: "재생시간 : 1분 15초",
    img: "whs_image17",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_17_export",
  },
  {
    ebKey: "kga_whs_video_18",
    title: "플레잉핸디캡",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 2. [개괄]",
    issueDate: "재생시간 : 1분 01초",
    img: "whs_image18",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_18_export",
  },
  {
    ebKey: "kga_whs_video_19",
    title: "핸디캡평가",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 2. [개괄]",
    issueDate: "재생시간 : 1분 08초",
    img: "whs_image19",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_19_export",
  },
];

export const videoDataSIMHWA = [
  {
    ebKey: "kga_whs_video_24",
    title: "대회의 형평성",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 3. [심화]",
    issueDate: "재생시간 : 01분 53초",
    img: "whs_image24",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_24_export",
    isNew: "true",
  },
  {
    ebKey: "kga_whs_video_25",
    title: "슬로프레이팅",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 3. [심화]",
    issueDate: "재생시간 : 01분 42초",
    img: "whs_image25",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_25_export",
    isNew: "true",
  },
  {
    ebKey: "kga_whs_video_20",
    title: "코스레이팅과 슬로프레이팅",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 3. [심화]",
    issueDate: "재생시간 : 2분 46초",
    img: "whs_image20",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_20_export",
  },

  {
    ebKey: "kga_whs_video_21",
    title: "핸디캡 공식",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 3. [심화]",
    issueDate: "재생시간 : 3분 03초",
    img: "whs_image21",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_21_export",
  },

  {
    ebKey: "kga_whs_video_22",
    title: "골퍼의 여정",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 3. [심화]",
    issueDate: "재생시간 : 3분 06초",
    img: "whs_image22",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_22_export",
  },

  {
    ebKey: "kga_whs_video_23",
    title: "포괄적 & 안전장치",
    subTitle: "월드핸디캡시스템(WHS) 안내",
    description: "PART 3. [심화]",
    issueDate: "재생시간 : 3분 04초",
    img: "whs_image23",
    url: "http://kgabook.or.kr/eBook/KGA_WHSmov_23_export",
  },
];
