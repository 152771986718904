import {
  DesktopEBookCardContainer,
  DesktopEBookCardLeftBlock,
  DesktopEBookCardMetaContainer,
  DesktopEBookCardRightBlock,
  DesktopEBookCardTextBox,
  DesktopEBookCardVerticalBar,
  DesktopEBookImageWrapper,
} from "./DesktopEBookCard.styles";

const DesktopEBookCard = ({
  containerPadding,
  leftBlockPadding,
  image,
  title,
  contentBold,
  content,
  issuedAt,
  publication,
  registration,
  imagePadding,
  linkUrl,
  ebKey,
}) => {
  return (
    <DesktopEBookCardContainer style={{ padding: containerPadding }}>
      <DesktopEBookCardLeftBlock
        style={{ padding: leftBlockPadding, margin: "auto" }}>
        <DesktopEBookImageWrapper>
          <a href={linkUrl} target="_blank">
            <img src={image} alt="" />
          </a>
        </DesktopEBookImageWrapper>
      </DesktopEBookCardLeftBlock>

      <DesktopEBookCardRightBlock>
        <DesktopEBookCardVerticalBar />
        <a href={linkUrl} target="_blank">
          <h3>{title}</h3>
        </a>
        <DesktopEBookCardTextBox>
          <b>{contentBold}</b>
          {content}
        </DesktopEBookCardTextBox>
        {ebKey === "3" ? (
          ""
        ) : (
          <DesktopEBookCardMetaContainer>
            <div>
              <b>발행일</b>
              <span>{issuedAt}</span>
            </div>
            <div>
              <b>편집 · 발행</b>
              <span>{publication}</span>
            </div>
            {registration && (
              <div>
                <b>등록</b>
                <span>{registration}</span>
              </div>
            )}
            <b>무단 복제 및 전재를 금합니다.</b>
          </DesktopEBookCardMetaContainer>
        )}
      </DesktopEBookCardRightBlock>
    </DesktopEBookCardContainer>
  );
};

export default DesktopEBookCard;
