import styled from "@emotion/styled";
import media from "styles/media";

export const EBookCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 20;

  ${media.desktop} {
    gap: 100px;
  }
`;

export const MobileEBookWarning = styled.span`
  font-size: 10px;
  transform: scale(0.9);
  line-height: 9px;
  letter-spacing: -0.36px;

  display: block;
  width: fit-content;
  margin: 0 auto;
  margin-top: 25px;
  z-index: 10;

  ${media.desktop} {
    display: none;
  }
`;
