import styled from "@emotion/styled";
import { colors } from "styles/colors";
import media from "styles/media";

export const MobileEBookCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;

  ${media.desktop} {
    display: none;
  }
`;

export const MobileEBookImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #f8f8f8cc;
  box-shadow: 2px 2px 5px #00000029;
  border-radius: 10px;

  padding: 23px 55px;

  img {
    width: 200px;
    height: auto;
    //width: 100%;
    //height: 100%;
    object-fit: cover;
    box-shadow: 2px 2px 5px #00000029;
  }
`;

export const MobileEBookTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: left;
`;

export const MobileEBookDescriptionTitle = styled.h3`
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.6px;
  color: ${colors.BLUE2};
`;

export const MobileEBookDescriptionContent = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.72px;

  white-space: pre-wrap;

  b {
    font-weight: 700;
  }
`;

export const MobileEBookDescriptionMetaContainer = styled.div`
  display: flex;
  //justify-content: start;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

export const MobileEBookDescriptionMetaBlock = styled.div`
  font-weight: 300;
  font-size: 10px;
  //transform: scale(0.9);
  line-height: 9px;
  letter-spacing: -0.36px;
  color: #00000080;

  b {
    font-weight: 500;
    margin-right: 4px;
  }
`;
