import React, { useCallback, useState, useEffect } from "react";
import EBookTab from "./EBookTab/EBookTab";
import { BannerHorizontalBar } from "./Home.styels";
import HomeBanner from "./HomeBanner/HomeBanner";
import HomeTabList from "./HomeTabList/HomeTabList";
import VideoTab from "./VideoTab/VideoTab";
import { APIRequest } from "common/Common";

const Home = () => {
  const [tab, setTab] = useState("e-book");

  useEffect(() => {}, []);

  const onClickTab = useCallback(
    (tab) => () => {
      setTab(tab);
    },
    []
  );
  return (
    <>
      <HomeBanner tab={tab} />
      <BannerHorizontalBar />
      <HomeTabList tab={tab} onClickTab={onClickTab} />
      {tab === "e-book" ? <EBookTab /> : <VideoTab />}
    </>
  );
};

export default Home;
