import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from 'styles/colors';
import media from 'styles/media';

export const TabListContainer = styled.div`
  background: linear-gradient(
    90deg,
    #00000000 0%,
    #02091960 50%,
    #05194600 100%
  );

  ${media.desktop} {
    min-width: 1355px;
  }
`;

export const TabList = styled.ul`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  height: 30px;

  ${media.desktop} {
    width: 100%;
    max-width: 1370px;
    margin: 0 auto;

    height: 60px;
    justify-content: start;
    margin-bottom: 130px;
  }
`;

export const TabListItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.48px;
  color: ${colors.GRAY3};

  width: 125px;
  height: 30px;
  background: ${colors.WHITE};
  border-radius: 0px 0px 10px 10px;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${colors.BLUE1};
      color: ${colors.WHITE};
    `}

  ${media.desktop} {
    width: 310px;
    height: 60px;
    border-radius: 0px 0px 20px 20px;

    font-size: 31px;
    line-height: 46px;
    letter-spacing: -1.24px;
  }
`;
