import React from "react";
import bannerLogo from "assets/images/kga_logo_2.png";
import {
  Banner,
  BannerBackground,
  DesktopBannerBlock,
  DesktopBannerLogoWrapper,
  DesktopBannerTextContainer,
  MobileBannerTextContainer,
  MobileBannerTitle,
  mobileContainer,
} from "./HomeBanner.styles";

const HomeBanner = ({ tab }) => {
  return (
    <Banner>
      <BannerBackground />
      {/* Mobile */}
      <MobileBannerTitle
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}>
        <div>
          <div
            style={{
              display: "flex",
              fontSize: "10px",
              marginTop: "5px",
              letterSpacing: "-0.4px",
            }}>
            <span>대한민국 골프의 중심.</span>
            <div style={{ marginLeft: "5px", fontWeight: "700" }}>
              <b>대한골프협회</b>
            </div>
            <span />
          </div>
          <div
            style={{
              fontWeight: "900",
              fontSize: "20px",
              marginTop: "7px",
              letterSpacing: "-0.4px",
            }}>
            E - 북 리스트
          </div>
        </div>
        <MobileBannerTextContainer>
          <img src={bannerLogo} alt="banner_logo" />
        </MobileBannerTextContainer>
      </MobileBannerTitle>
      {/* Desktop */}
      <DesktopBannerBlock>
        <DesktopBannerTextContainer>
          <p>
            <span>대한민국 골프의 중심.{"  "}</span>
            <b>대한골프협회</b>
            <span />
          </p>
          <p className="desktop_banner_title">E - 북리스트</p>
          <p>핸디캡 E-Book / 핸디캡의 모든 내용을 안내해 드립니다.</p>
        </DesktopBannerTextContainer>
        <DesktopBannerLogoWrapper>
          <img src={bannerLogo} alt="banner_logo" />
        </DesktopBannerLogoWrapper>
      </DesktopBannerBlock>
    </Banner>
  );
};

export default HomeBanner;
