import { Global } from '@emotion/react';
import AppLayout from 'components/AppLayout';
import Home from 'pages/home/Home';
import globalStyles from 'styles/global.styles';

function App() {
  return (
    <>
      <Global styles={globalStyles} />

      <AppLayout>
        <Home />
      </AppLayout>
    </>
  );
}

export default App;
