import styled from "@emotion/styled";
import media from "styles/media";

export const UploadLaterText = styled.p`
  font-weight: 300;
  font-size: 12px;
  font-weight: 20px;
  letter-spacing: -0.48px;
  text-align: center;
  margin-bottom: 635px;

  ${media.desktop} {
    font-size: 20px;
    line-height: 31px;
    letter-spacing: -0.8px;
    margin-bottom: 936px;
  }
`;

export const VideoCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 20;

  ${media.desktop} {
    gap: 100px;
  }
`;

export const MobileVideoWarning = styled.span`
  font-size: 10px;
  transform: scale(0.9);
  line-height: 9px;
  letter-spacing: -0.36px;

  display: block;
  width: fit-content;
  margin: 0 auto;
  margin-top: 25px;
  z-index: 10;

  ${media.desktop} {
    display: none;
  }
`;
