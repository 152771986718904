export const colors = {
  /** #FFFFFF */
  WHITE: '#FFFFFF',
  /** #000000 */
  BLACK: '#000000',

  /** #003295 */
  BLUE1: '#003295',
  /** #0A328C */
  BLUE2: '#0A328C',
  /** #003A93 */
  BLUE3: '#003A93',

  /** #00000080 */
  GRAY1: '#00000080',
  /** #707070 */
  GRAY2: '#707070',
  /** #5A5A5A */
  GRAY3: '#5A5A5A',
  /** #0000004D */
  GRAY4: '#0000004D',
};
