import styled from "@emotion/styled";
import { colors } from "styles/colors";
import media from "styles/media";

export const DesktopEBookCardContainer = styled.div`
  display: none;

  ${media.desktop} {
    min-width: 1355px;
    height: 350px;
    background: #f8f8f8cc;
    box-shadow: 6px 6px 15px #00000029;
    border-radius: 20px;

    display: grid;
    grid-template-columns: 735px 620px;

    padding: 100px 0;
  }
`;

export const DesktopEBookCardLeftBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DesktopEBookImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  img {
    width: 450px;
    //width : 100%
    height: 250px;
    //height : 100%
    object-fit: cover;
    box-shadow: 0px 3px 6px #00000029;
  }
`;

export const DesktopEBookCardRightBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 35px;
  white-space: pre-wrap;

  padding-left: 62.75px;

  h3 {
    font-weight: 700;
    font-size: 35px;
    line-height: 52px;
    letter-spacing: -1.4px;
    color: #0a328c;
  }
`;

export const DesktopEBookCardVerticalBar = styled.div`
  height: 35px;
  width: 1px;
  background: ${colors.GRAY2};

  position: absolute;
  top: 8.5px;
  left: 0;
`;

export const DesktopEBookCardTextBox = styled.div`
  font-weight: 300;
  font-size: 20px;
  line-height: 31px;
  letter-spacing: -0.8px;

  b {
    font-weight: 700;
  }
`;

export const DesktopEBookCardMetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.64px;

  div {
    display: flex;
    flex-direction: column;
  }

  b {
    font-weight: 500;
  }
`;
