import styled from "@emotion/styled";
import { colors } from "styles/colors";
import media from "styles/media";

export const HeaderContainer = styled.header`
  //position: sticky;
  //top: 0;
  background: ${colors.WHITE};
  z-index: 100;

  padding: 10px 40px;
  height: 45px;

  display: flex;
  align-items: center;

  ${media.desktop} {
    padding: 57px 0 49px;
    height: 175px;
    min-width: 1355px;
  }
`;

export const HeaderBlock = styled.div`
  width: 100%;
  max-width: 1370px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  width: 70px;
  height: 24px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ${media.desktop} {
    width: 200px;
    height: 70px;
  }
`;

export const HeaderLink = styled.a`
  display: none;

  ${media.desktop} {
    display: inline-block;
    font-size: 17px;
    line-height: 25px;
    font-weight: bold;
    letter-spacing: -0.68px;
    color: ${colors.BLUE1};
  }
`;
