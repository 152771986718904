import {
  MobileEBookDescriptionContent,
  MobileEBookDescriptionMetaBlock,
  MobileEBookDescriptionMetaContainer,
  MobileEBookDescriptionTitle,
  MobileEBookImageContainer,
  MobileEBookTextContainer,
} from "./MobileVideoCard.styles";
import { MobileEBookCardContainer } from "./MobileVideoCard.styles";

const MobileVideoCard = ({
  image,
  title,
  contentBold,
  content,
  playTime,
  imagePadding,
  linkUrl,
  isNew,
}) => {
  return (
    <MobileEBookCardContainer style={{ position: "relative" }}>
      {isNew === "true" ? (
        <div style={{ position: "absolute", top: "-16px", left: "-13px" }}>
          <img
            width={"100px"}
            src={require(`../../../../assets/images/New_icon.png`)}
            alt=""
          />
        </div>
      ) : (
        ""
      )}
      <MobileEBookImageContainer style={{ padding: imagePadding }}>
        <a href={linkUrl}>
          <img
            style={{ borderRadius: "10px" }}
            src={require(`../../../../assets/images/${image}.png`)}
            alt="easyUnderstanding"
          />
        </a>
        <MobileEBookDescriptionTitle>
          <a href={linkUrl}>{title} </a>
        </MobileEBookDescriptionTitle>
        <MobileEBookDescriptionContent>
          <b style={{ fontSize: "9px" }}>{contentBold}</b>
        </MobileEBookDescriptionContent>
        <MobileEBookDescriptionMetaBlock>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <span style={{ fontSize: "9px", fontWeight: "500" }}>
              {content}
            </span>
            <span
              style={{
                fontSize: "9px",
                marginTop: "5px",
                fontWeight: "500",
              }}>
              {playTime}
            </span>
          </div>
        </MobileEBookDescriptionMetaBlock>
      </MobileEBookImageContainer>
    </MobileEBookCardContainer>
  );
};

export default MobileVideoCard;
