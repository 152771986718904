import {
  DesktopEBookCardContainer,
  DesktopEBookCardLeftBlock,
  DesktopEBookCardMetaContainer,
  DesktopEBookCardRightBlock,
  DesktopEBookCardTextBox,
  DesktopEBookCardVerticalBar,
  DesktopEBookImageWrapper,
} from "./DesktopVideoCard.styles";
import whs_image1 from "../../../../assets/images/whs_image1.png";
const DesktopVideoCard = ({
  containerPadding,
  leftBlockPadding,
  image,
  title,
  contentBold,
  content,
  playTime,
  linkUrl,
  isNew,
}) => {
  console.log(image);
  return (
    <DesktopEBookCardContainer
      style={{ padding: containerPadding, position: "relative" }}>
      {isNew === "true" ? (
        <div style={{ position: "absolute", top: "-30px", left: "-22px" }}>
          <img src={require(`../../../../assets/images/New_icon.png`)} alt="" />
        </div>
      ) : (
        ""
      )}

      <DesktopEBookCardLeftBlock
        style={{ padding: leftBlockPadding, margin: "auto" }}>
        <DesktopEBookImageWrapper>
          <div>
            <a href={linkUrl} target="_blank">
              <img
                src={require(`../../../../assets/images/${image}.png`)}
                alt=""
                style={{ borderRadius: "10px" }}
              />
            </a>
          </div>
        </DesktopEBookImageWrapper>
      </DesktopEBookCardLeftBlock>

      <DesktopEBookCardRightBlock>
        <DesktopEBookCardVerticalBar />
        <a href={linkUrl} target="_blank">
          <h3>{title}</h3>
        </a>
        <DesktopEBookCardTextBox>
          <b>{contentBold}</b>
        </DesktopEBookCardTextBox>
        <DesktopEBookCardMetaContainer>
          <div>
            <span style={{ fontSize: "20px" }}>{content}</span>
            <span style={{ fontSize: "20px", marginTop: "5px" }}>
              {playTime}
            </span>
          </div>
        </DesktopEBookCardMetaContainer>
      </DesktopEBookCardRightBlock>
    </DesktopEBookCardContainer>
  );
};

export default DesktopVideoCard;
