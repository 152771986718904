import styled from '@emotion/styled';
import Footer from './Footer/Footer';
import Header from './Header/Header';

const AppLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

const Main = styled.main`
  position: relative;
`;

export default AppLayout;
