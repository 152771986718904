import styled from "@emotion/styled";
import { colors } from "styles/colors";
import media from "styles/media";

export const FooterBlock = styled.footer`
  height: 55px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.desktop} {
    height: 265px;
    gap: 90px;
    min-width: 1355px;
  }
`;

export const FooterLogoWrapper = styled.div`
  display: none;

  ${media.desktop} {
    display: block;
    width: 220px;
    height: 76px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const FooterInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  font-size: 10px;
  line-height: 11px;
  letter-spacing: -0.3px;
  color: ${colors.GRAY2};
  text-align: center;

  p {
    transform: scale(0.8);
  }

  ${media.desktop} {
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -0.8px;
    text-align: left;

    p {
      transform: unset;
    }
  }
`;

export const FooterTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  transform: scale(0.8);

  ${media.desktop} {
    gap: 25px;
    transform: unset;
  }
`;

export const VerticalBar = styled.div`
  height: 7px;
  width: 1px;
  background: ${colors.GRAY2};

  ${media.desktop} {
    height: 16px;
    width: 2px;
  }
`;
