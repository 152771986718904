import { useState, useEffect } from "react";
import {
  FooterBlock,
  FooterInfoContainer,
  FooterLogoWrapper,
  FooterTextContainer,
  VerticalBar,
} from "./Footer.styles";
import logo from "assets/images/kga_logo_1.png";
import media from "styles/media";

const Footer = () => {
  const [resize, setResize] = useState(window.innerWidth);

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <FooterBlock>
      <FooterLogoWrapper>
        <img src={logo} alt="logo" />
      </FooterLogoWrapper>

      <FooterInfoContainer>
        <FooterTextContainer>
          {resize >= 600 ? (
            <span>10881 경기도 파주시 회동길 174 (문발동)</span>
          ) : (
            <span>10881 경기도 파주시 회동길 174</span>
          )}

          <VerticalBar />
          <span>Tel 031 - 540 - 5700</span>
          <VerticalBar />
          <span>Email hdcp@kgagolf.or.kr</span>
        </FooterTextContainer>
        <p>Copyright ⓒ KGA All Right Reserved.</p>
      </FooterInfoContainer>
    </FooterBlock>
  );
};

export default Footer;
