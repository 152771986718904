import axios from "axios";

/* 예외처리 */
export class UnexpectedApiResultError {
  constructor(resultCode) {
    this.resultCode = resultCode;
  }
}

export const APIRequest = (request, addParams) => {
  return new Promise((resolve, reject) => {
    ////console.log(params);
    axios({
      method: "post",
      url: "https://api.kgabook.or.kr" + request,
      //url: 'http://localhost' + request,
      // params: params,
      data: addParams,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
