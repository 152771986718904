import React from "react";
import { useState, useEffect, useCallback } from "react";
import {
  CategoryBackground,
  CategoryHorizontalBar,
  CategoryList,
  CategoryListContainer,
  CategoryListItem,
  CategoryVerticalBar,
  CategoryBody,
} from "../Home.styels";
import { UploadLaterText } from "./VideoTab.styles";
import MobileVideoCard from "./MobileVideoCard/MobileVideoCard";
import DesktopVideoCard from "./DesktopVideoCard/DesktopVideoCard";
import { APIRequest } from "common/Common";
import { VideoCardList, MobileVideoWarning } from "./VideoTab.styles";
import { videoDataWHS, videoDataGAEGWAL, videoDataSIMHWA } from "common/Data";
import ashp from "../../../assets/images/whs_image1.png";

const VideoTab = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [videoCategory, setVideoCategory] = useState("simhwa");
  const [videoData, setVideoData] = useState(videoDataSIMHWA);
  const urlData = {
    whsMovie: "http://kgabook.or.kr/eBook/KGA_WHS_movie_1",
  };

  useEffect(() => {}, []);

  const onClickCategory = useCallback(
    (category) => () => {
      setVideoCategory(category);
      category === "gaegwal"
        ? setVideoData(videoDataGAEGWAL)
        : category === "simhwa"
        ? setVideoData(videoDataSIMHWA)
        : setVideoData(videoDataWHS);
    },
    []
  );

  return (
    <CategoryBody>
      <CategoryListContainer>
        <CategoryHorizontalBar />
        <CategoryVerticalBar />
        <CategoryList>
          <CategoryListItem
            isActive={videoCategory === "whs"}
            onClick={onClickCategory("whs")}>
            WHS 안내
          </CategoryListItem>
          <CategoryVerticalBar />
          <CategoryListItem
            isActive={videoCategory === "gaegwal"}
            onClick={onClickCategory("gaegwal")}>
            개괄
          </CategoryListItem>
          <CategoryVerticalBar />
          <CategoryListItem
            isActive={videoCategory === "simhwa"}
            onClick={onClickCategory("simhwa")}>
            심화
          </CategoryListItem>
        </CategoryList>
        <CategoryVerticalBar />
        <CategoryHorizontalBar />
      </CategoryListContainer>
      <VideoCardList>
        {videoData &&
          videoData.map((item) => (
            <>
              <MobileVideoCard
                ket={item.ebKey}
                image={item.img}
                title={item.title}
                contentBold={item.subTitle}
                content={item.description}
                playTime={item.issueDate}
                linkUrl={item.url}
                isNew={item.isNew}
              />
              <DesktopVideoCard
                ket={item.ebKey}
                containerPadding="50px 0"
                image={item.img}
                title={item.title}
                contentBold={item.subTitle}
                content={item.description}
                playTime={item.issueDate}
                linkUrl={item.url}
                isNew={item.isNew}
              />
            </>
          ))}

        {/* Desktop */}
      </VideoCardList>

      <CategoryBackground>
        <div />
        <MobileVideoWarning>무단 복제 및 전재를 금합니다.</MobileVideoWarning>
      </CategoryBackground>
    </CategoryBody>
  );
};

export default VideoTab;
