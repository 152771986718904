import { css } from '@emotion/react';
import { colors } from './colors';
import './fonts.css';
import './reset.css';

const globalStyles = css`
  * {
    box-sizing: border-box;
    font-family: 'Noto Sans KR' !important;
  }

  body {
    background: ${colors.WHITE};
    color: ${colors.BLACK};
  }

  button {
    cursor: pointer;
    border: none;
    &:disabled {
      cursor: not-allowed;
    }
  }

  input {
    outline: none;
  }

  a {
    text-decoration: none;
    color: unset;
    cursor: pointer;
  }

  svg {
    display: block;
    cursor: pointer;
  }
`;

export default globalStyles;
