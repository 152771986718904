import styled from "@emotion/styled";
import bannerBackground from "assets/images/banner_background.jpeg";
import { colors } from "styles/colors";
import media from "styles/media";

export const Banner = styled.div`
  position: relative;
  padding: 29px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  background-image: ${`url(${bannerBackground})`};
  background-position: center;
  background-size: cover;

  ${media.desktop} {
    padding: 114px 0;
    justify-content: space-between;
    min-width: 1355px;
  }
`;

export const BannerBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    180deg,
    #003a93 0%,
    #0062ff71 57%,
    #ffffff00 100%
  );
  opacity: 0.3;
`;

export const mobileContainer = styled.div`
  width: 100%;
  display: flex;

  ${media.desktop} {
    display: none;
  }
`;

export const MobileBannerTitle = styled.h2`
  display: flex;
  justify-content: space-between
  width: 100%
  font-size: 11px;
  letter-spacing: -0.5px;
  color: ${colors.WHITE};
  text-shadow: 0.2px 0.2px 1px #00000029;
  z-index: 10;

  ${media.desktop} {
    display: none;
  }
`;

export const MobileBannerTextContainer = styled.div`
  display: block;
  width: 55px;
  height: 53px;
  z-index: 10;
  img {
    width: 55px;
    height: 53px;
    object-fit: cover;
  }

  ${media.desktop} {
    display: none;
  }
`;

export const DesktopBannerBlock = styled.div`
  display: none;

  ${media.desktop} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1370px;
    margin: 0 auto;
  }
`;

export const DesktopBannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;

  p {
    font-weight: 300;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: -1.2px;
    color: ${colors.WHITE};

    b {
      font-weight: 700;
    }

    &.desktop_banner_title {
      font-size: 55px;
      line-height: 82px;
      font-weight: 700;
    }
  }

  p:first-of-type {
    display: flex;
    align-items: center;
    margin-bottom: 49px;
    white-space: pre-wrap;

    span:last-of-type {
      width: 350px;
      margin-left: 12px;
      height: 1px;
      background: ${colors.WHITE};
    }
  }
`;

export const DesktopBannerLogoWrapper = styled.div`
  display: block;
  width: 220px;
  height: 214px;
  z-index: 10;

  img {
    width: 100%;
    object-fit: cover;
  }
`;
