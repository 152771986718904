import React, { useCallback, useState, useEffect } from "react";
import {
  CategoryBackground,
  CategoryBody,
  CategoryHorizontalBar,
  CategoryList,
  CategoryListContainer,
  CategoryListItem,
  CategoryVerticalBar,
} from "../Home.styels";
import easyUnderstanding from "assets/images/easy_understanding.png";
import handicapRules from "assets/images/handicap_rules.jpeg";
import handicapSystem from "assets/images/handicap_system.jpg";
import MobileEBookCard from "./MobileEBookCard/MobileEBookCard";
import { EBookCardList, MobileEBookWarning } from "./EBookTab.styles";
import DesktopEBookCard from "./DesktopEBookCard/DesktopEBookCard";
import { APIRequest } from "common/Common";
const EBookTab = (props) => {
  const [category, setCategory] = useState("all");
  const [ebookData, setEbookData] = useState("");
  const onClickCategory = useCallback(
    (category) => () => {
      setCategory(category);
    },
    []
  );

  useEffect(() => {
    APIRequest("/getEBookList", { comcd: "kga" })
      .then((res) => {
        console.log("결과", res.data);
        let videoTemp = [];
        res.data.map((item) => {
          if (item.ebKey.includes("video")) {
            console.log(item, "item");
            videoTemp.push(item);
          }
        });
        setEbookData(res.data);
      })
      .catch((e) => {
        console.log("e", e);
      });
  }, []);
  return (
    <CategoryBody>
      <CategoryListContainer>
        <CategoryHorizontalBar />
        <CategoryVerticalBar />
        <CategoryList>
          <CategoryListItem
            isActive={true}
            onClick={onClickCategory("handicap")}>
            골프 핸디캡
          </CategoryListItem>
        </CategoryList>
        <CategoryVerticalBar />
        <CategoryHorizontalBar />
      </CategoryListContainer>

      <EBookCardList>
        {ebookData &&
          ebookData.map((item) =>
            item.ebKey.includes("video") == false ? (
              <MobileEBookCard
                key={item.ebKey}
                image={
                  item.ebKey === "2"
                    ? handicapRules
                    : item.ebKey === "3"
                    ? handicapSystem
                    : `https://admin.kgabook.or.kr/image${item.img}`
                }
                ebKey={item.ebKey}
                title={item.title}
                linkUrl={item.url}
                contentBold={item.subTitle}
                content={`${item.description}`}
                issuedAt={item.issueDate}
                publication={item.issue}
                registration={item.reg}
              />
            ) : (
              ""
            )
          )}
        {/* Mobile 
        <MobileEBookCard
          image={easyUnderstanding}
          title={"골프 핸디캡의 쉬운 이해"}
          linkUrl={urlData.handicap}
          contentBold={"‘골프핸디캡의 쉬운 이해’"}
          content={
            " 는 2020년 전 세계 동시 시행된\n월드핸디캡시스템(WHS)의 전반적인 내용 소개와 골프 핸디캡에\n관한 기초 정보를 제공할 목적으로 구성되어 있습니다."
          }
          issuedAt={"2022년 3월 10일"}
          publication={"대한골프협회 출판사업부"}
        />
        <MobileEBookCard
          image={handicapRules}
          title={"핸디캡 규칙"}
          linkUrl={urlData.handicapRules}
          contentBold={"‘핸디캡 규칙’"}
          content={
            " 은 USGA와 R&A가 골프를 플레이하는\n모든 사람이 공정한 기준에 따라 골퍼의 실력을 일관성 있게\n평가할 수 있도록 돕고자 발간하게 되었습니다."
          }
          issuedAt={"1쇄 2021년 2월 1일"}
          publication={"대한골프협회 출판사업부"}
          registration={"2009년 6월 29일 제 406 - 2009 - 000084 호"}
          imagePadding={"12px 112.5px "}
        />*/}

        {/* Desktop */}
        {ebookData &&
          ebookData.map((item) =>
            item.ebKey.includes("video") == false ? (
              <DesktopEBookCard
                key={item.ebKey}
                containerPadding="55px 0"
                image={
                  item.ebKey === "2"
                    ? handicapRules
                    : item.ebKey === "3"
                    ? handicapSystem
                    : `https://admin.kgabook.or.kr/image${item.img}`
                }
                ebKey={item.ebKey}
                title={item.title} // 제목
                linkUrl={item.url} //링크주소
                contentBold={item.subTitle}
                content={`\n\n${item.description}`}
                issuedAt={item.issueDate}
                publication={item.issue}
                registration={item.reg}
              />
            ) : (
              ""
            )
          )}
        {/* 
        <DesktopEBookCard
          leftBlockPadding="75px 70px"
          image={easyUnderstanding}
          title={"골프 핸디캡의 쉬운 이해"}
          linkUrl={urlData.handicap}
          contentBold={"‘골프핸디캡의 쉬운 이해’ 는"}
          content={
            "\n\n2020년 전 세계 동시 시행된 월드핸디캡시스템(WHS)의\n전반적인 내용 소개와 골프 핸디캡에 관한 기초 정보를\n제공할 목적으로 구성되어 있습니다."
          }
          issuedAt={"2022년 3월 10일"}
          publication={"대한골프협회 출판사업부"}
        />
        <DesktopEBookCard
          containerPadding="65px 0"
          leftBlockPadding="0 190px"
          image={handicapRules}
          title={"핸디캡 규칙"}
          linkUrl={urlData.handicapRules}
          contentBold={"‘핸디캡 규칙’ 은"}
          content={
            "\n\nUSGA와 R&A가 골프를 플레이하는 모든 사람이 공정한\n기준에 따라 골퍼의 실력을 일관성 있게 평가할 수 있도록\n돕고자 발간하게 되었습니다."
          }
          issuedAt={"2021년 2월 1일"}
          publication={"대한골프협회 출판사업부"}
          registration={"2009년 6월 29일 제 406 - 2009 - 000084 호"}
        />*/}
      </EBookCardList>

      <CategoryBackground>
        <div />
        <MobileEBookWarning>무단 복제 및 전재를 금합니다.</MobileEBookWarning>
      </CategoryBackground>
    </CategoryBody>
  );
};

export default EBookTab;
