import {
  MobileEBookDescriptionContent,
  MobileEBookDescriptionMetaBlock,
  MobileEBookDescriptionMetaContainer,
  MobileEBookDescriptionTitle,
  MobileEBookImageContainer,
  MobileEBookTextContainer,
} from "./MobileEBookCard.styles";
import { MobileEBookCardContainer } from "./MobileEBookCard.styles";

const MobileEBookCard = ({
  image,
  title,
  contentBold,
  content,
  issuedAt,
  publication,
  registration,
  imagePadding,
  linkUrl,
  ebKey,
}) => {
  return (
    <MobileEBookCardContainer>
      <MobileEBookImageContainer style={{ padding: imagePadding }}>
        <a href={linkUrl}>
          <img src={image} alt="easyUnderstanding" />
        </a>
      </MobileEBookImageContainer>
      <MobileEBookTextContainer>
        <MobileEBookDescriptionTitle>
          <a href={linkUrl}>{title} </a>
        </MobileEBookDescriptionTitle>

        <MobileEBookDescriptionContent>
          <b>{contentBold}</b>
          {content}
        </MobileEBookDescriptionContent>

        {ebKey === "3" ? (
          ""
        ) : (
          <MobileEBookDescriptionMetaContainer>
            <MobileEBookDescriptionMetaBlock>
              <b>발행일</b>
              <span>{issuedAt}</span>
            </MobileEBookDescriptionMetaBlock>
            <MobileEBookDescriptionMetaBlock>
              <b>편집 · 발행</b>
              <span>{publication}</span>
            </MobileEBookDescriptionMetaBlock>

            {registration && (
              <MobileEBookDescriptionMetaBlock>
                <b>등록</b>
                <span>{registration}</span>
              </MobileEBookDescriptionMetaBlock>
            )}
          </MobileEBookDescriptionMetaContainer>
        )}
      </MobileEBookTextContainer>
    </MobileEBookCardContainer>
  );
};

export default MobileEBookCard;
