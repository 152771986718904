import { TabList, TabListContainer, TabListItem } from "./HomeTabList.styles";

const HomeTabList = ({ tab, onClickTab }) => {
  return (
    <TabListContainer>
      <TabList>
        <TabListItem isActive={tab === "e-book"} onClick={onClickTab("e-book")}>
          Publication
        </TabListItem>
        <TabListItem isActive={tab === "video"} onClick={onClickTab("video")}>
          동영상
        </TabListItem>
      </TabList>
    </TabListContainer>
  );
};

export default HomeTabList;
